import {BrowserView, MobileView} from "react-device-detect";
import Carousel from "react-material-ui-carousel/dist/components/Carousel";
import {Grid, Typography} from "@mui/material";
import React from "react";

export default function HomeCarousel() {
    return (
        <Grid item xs={12}>
            <BrowserView>
                <Carousel animation='fade' height='100vh' indicators={false} autoPlay={false}>
                    <img className='carousel-gradient' src='/carousel/carousel-1-1.jpeg'
                         style={{objectPosition: '80% 90%'}}/>
                    <img className='carousel-gradient' src='/carousel/carousel-1-7.jpeg'
                         style={{objectPosition: '40% 50%'}}/>
                    <img className='carousel-gradient' src='/carousel/carousel-1-5.jpeg'
                         style={{objectPosition: '60% 50%'}}/>
                    <img className='carousel-gradient' src='/carousel/carousel-1-2.jpeg'/>
                    <img className='carousel-gradient' src='/carousel/carousel-1-10.jpeg'/>
                    <img className='carousel-gradient' src='/carousel/carousel-1-9.jpeg'/>
                    <img className='carousel-gradient' src='/carousel/carousel-1-6.jpeg'
                         style={{objectPosition: '60% 50%'}}/>
                    <img className='carousel-gradient' src='/carousel/carousel-1-4.jpeg'/>
                    <img className='carousel-gradient' src='/carousel/carousel-1-8.jpeg'/>
                    <img className='carousel-gradient' src='/carousel/carousel-1-3.jpeg'/>
                </Carousel>
                <div style={{
                    bottom: '0.2rem',
                    fontSize: '1.2rem',
                    position: 'absolute',
                    zIndex: '1',
                    width: '-webkit-fill-available',
                    textAlign: 'center'
                }}>
                    <img src='/logo512.png' style={{width: '8%'}}/>
                </div>
            </BrowserView>
            <MobileView>
                <Carousel animation='fade' height='100vh' indicators={false}>
                    <img className='carousel-gradient-mobile' src='/carousel/carousel-1-7.jpeg'
                         style={{objectPosition: '45% 0%'}}/>
                    <img className='carousel-gradient-mobile' src='/carousel/carousel-1-1.jpeg'
                         style={{objectPosition: '70% 0%'}}/>
                    <img className='carousel-gradient-mobile' src='/carousel/carousel-1-5.jpeg'
                         style={{objectPosition: '52% 0%'}}/>
                    <img className='carousel-gradient-mobile' src='/carousel/carousel-1-6.jpeg'/>
                    <img className='carousel-gradient-mobile' src='/carousel/carousel-1-10.jpeg'
                         style={{objectPosition: '80% 0%'}}/>
                </Carousel>
                <div style={{
                    bottom: '0.2rem',
                    fontSize: '1.2rem',
                    position: 'absolute',
                    zIndex: '1',
                    width: '-webkit-fill-available',
                    textAlign: 'center'
                }}>
                    <img src='/logo512.png' style={{width: '30%'}}/>
                </div>
            </MobileView>
        </Grid>
    );
}