import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import axios from 'axios';
import Home from "./pages/Home";
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";
import WeddingEnquiry from './pages/WeddingEnquiry';
import Quote from './pages/Quote';
import Delivery from './pages/Delivery';


axios.defaults.baseURL = "https://us-central1-habiot.cloudfunctions.net"
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <Suspense>
            <Router>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/weddingenquiry' element={<WeddingEnquiry />} />
                    <Route path='/quote/:id' element={<Quote />} />
                    <Route path='/delivery/:id' element={<Delivery />} />
                </Routes>
            </Router>
        </Suspense>
    </React.StrictMode>
);