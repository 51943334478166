import {Box, Container, Link, Typography} from "@mui/material";
import React from "react";

export default function Footer() {
    return (
        <Box component="footer" sx={{
            backgroundColor: 'black',
            pt: 10,
            pb: 1
        }}>
            <Container maxWidth="lg" sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <img src={'/logo.png'} width={'100px'} alt='footer' style={{zIndex: 1}}/>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="primary.light"
                    component="p"
                    sx={{pt: 3}}
                    zIndex={1}
                >
                    Real people, Real stories, Real moments.
                </Typography>
                <Typography variant="body2" color="primary.light" align="center" sx={{pt: 2, pb: 2}} zIndex={1}>
                    {'Copyright © '}
                    <Link color="inherit" href="https://signaturemoments.co.in/">
                        Signature Moments
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </Container>
        </Box>
    );
}