import { ThemeProvider } from "@emotion/react";
import GlobalTheme from "../theme/GlobalTheme";
import { Card, CardContent, CardMedia, Divider, Grid, responsiveFontSizes, Skeleton, Snackbar, SnackbarContent, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import PlaceIcon from '@mui/icons-material/Place';
import EventIcon from '@mui/icons-material/Event';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CelebrationIcon from '@mui/icons-material/Celebration';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import MovieIcon from '@mui/icons-material/Movie';

import axios from "axios";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";

import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import { motion } from "framer-motion";
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from "@mui/lab";
import ParallaxParticles from "../components/ParallaxParticles";

export default function Delivery() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [date, setDate] = useState("");
    const [location, setLocation] = useState("");
    const [coverPicture, setCoverPicture] = useState("");
    const [photosLink, setPhotosLink] = useState("");
    const [candidVideoLink, setCandidVideoLink] = useState("");
    const [traditionalVideoLink, setTraditionalVideoLink] = useState("");
    const [reelsLink, setReelsLink] = useState("");
    const [albumLink, setAlbumLink] = useState("");
    const [notes, setNotes] = useState("");

    const [showAlert, setShowAlert] = useState(true);
    const [alertMessage, setAlertMessage] = useState("Loading...");

    const bigDevice = useMediaQuery(GlobalTheme.breakpoints.up('md'));

    const targetRef = useRef<HTMLDivElement | null>(null);

    const scrollToDiv = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        axios.post("/signature-moments-data-ingest", {
            action: 'GET_DELIVERY_DETAILS',
            delivery_no: id,
            email: "1",
            password: "1"
        }).then(response => {
            if (response.status === 200) {
                setName(response.data[1]);
                setPhone(response.data[2]);
                setEmail(response.data[3]);
                setDate(response.data[4]);
                setLocation(response.data[5]);
                setCoverPicture(response.data[6]);
                setPhotosLink(response.data[7]);
                setCandidVideoLink(response.data[8]);
                setTraditionalVideoLink(response.data[9]);
                setReelsLink(response.data[10]);
                setAlbumLink(response.data[11]);
                setNotes(response.data[12]);
                setLoading(false);
                setShowAlert(false);
                setAlertMessage("");
                scrollToDiv();
            } else {
                setAlertMessage("Failed! Please try again.");
                setShowAlert(true);
            }
        }).catch(error => {
            setAlertMessage("Failed! Please try again.");
            setShowAlert(true);
        })
    }, []);

    return (
        <ThemeProvider theme={responsiveFontSizes(GlobalTheme)}>
            <Grid
                container
                component="main"
                textAlign={"center"}
                sx={{
                    backgroundColor: "primary.main",
                    minHeight: "100vh"
                }}
            >
                <Grid item xs={12} md={6} p={0} alignContent={"center"}>
                    <ParallaxParticles/>
                </Grid>
                {
                    loading ? bigDevice ? <Grid item xs={12} md={6} p={2} alignContent={"center"} sx={{ backgroundColor: "primary.light" }}>
                        <Grid container justifyContent={"center"} px={3}>
                            <Grid item xs={6}>
                                <Skeleton height={200} />
                            </Grid>
                            <Grid item xs={10}>
                                <Skeleton height={150} />
                            </Grid>
                            <Grid item xs={8}>
                                <Skeleton height={100} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={12} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid item xs={6} py={1}>
                                <Skeleton height={50} />
                            </Grid>
                        </Grid>
                    </Grid> : null : <Grid ref={targetRef} item xs={12} md={6} p={2} alignContent={"center"} sx={{ backgroundColor: "primary.light" }}>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{
                                opacity: 1,
                                y: 0,
                                transition: {
                                    duration: 1
                                }
                            }}
                            viewport={{ once: true }}
                        >
                            <Grid container>
                                <Grid item xs={12} pt={2}>
                                    <Typography variant="h3" fontWeight={"700"} p={1}>{name}</Typography>
                                </Grid>
                                <Grid item xs={12} py={3} sx={{ textAlign: '-webkit-center' }}>
                                    <motion.div
                                        initial={{ x: -300, opacity: 0 }}
                                        whileHover={{ scale: 1.1 }}
                                        whileInView={{
                                            x: 0,
                                            opacity: 1,
                                            transition: {
                                                type: "spring",
                                                bounce: 0.4,
                                                duration: 2
                                            }
                                        }}
                                        whileTap={{ scale: 0.9 }}
                                        viewport={{ once: true }}
                                    >
                                        <Card sx={{ width: '95%', maxWidth: 500, background: "black", borderRadius: 3 }}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <CardMedia
                                                        component="img"
                                                        sx={{ height: '100%' }}
                                                        image={coverPicture}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} p={1}>
                                                    <Grid item xs={12} py={1}>
                                                        <EventIcon color={"secondary"} />
                                                        <Typography variant="body1" color={"secondary"}>{date}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} py={1}>
                                                        <PlaceIcon color={"secondary"} />
                                                        <Typography color={"secondary"}>{location}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} py={1}>
                                                        <PhoneIcon color={"secondary"} />
                                                        <Typography color={"secondary"}>{phone}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </motion.div>

                                </Grid>
                                <Grid item xs={12} pb={1}>
                                    <Typography>{notes}</Typography>
                                </Grid>
                                <Grid item xs={12} py={2} px={{ xs: 2, md: 4, lg: 8, xl: 12 }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4" fontWeight={"700"} p={1}>Timeline</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Timeline sx={{
                                        [`& .${timelineOppositeContentClasses.root}`]: {
                                            flex: bigDevice ? 1.0 : 0.5,
                                        },
                                    }}>
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0.1 }}
                                            whileHover={{ scale: 1.1 }}
                                            whileInView={{
                                                opacity: 1,
                                                scale: 1.0,
                                                transition: {
                                                    duration: 1
                                                }
                                            }}
                                            whileTap={{ scale: 0.9 }}
                                            viewport={{ once: true }}
                                        >
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: 'auto 0' }}
                                                    align="right"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {date}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color="primary">
                                                        <CelebrationIcon />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ m: "auto 0" }}>
                                                    <Typography variant="h6" component="span">
                                                        Event Day
                                                    </Typography>
                                                    <Typography variant="body2">Real People, Real Stories, Real Moments</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </motion.div>
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0.1 }}
                                            whileHover={{ scale: 1.1 }}
                                            whileInView={{
                                                opacity: 1,
                                                scale: 1.0,
                                                transition: {
                                                    duration: 1
                                                }
                                            }}
                                            whileTap={{ scale: 0.9 }}
                                            viewport={{ once: true }}
                                        >
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: 'auto 0' }}
                                                    align="right"
                                                    variant="body2"
                                                    color={photosLink === "" ? "grey" : "primary"}
                                                >
                                                    {photosLink !== "" ? photosLink.split("|")[0] : "Processing..."}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color={photosLink === "" ? "grey" : "primary"}>
                                                        <PhotoCameraIcon />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ m: "auto 0" }}>
                                                    <Typography variant="h6" component="span">
                                                        Photos
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {photosLink !== "" ?
                                                            <Link to={photosLink.split("|")[1]} target="_blank" rel="noopener noreferrer">Link</Link>
                                                            : null}
                                                    </Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </motion.div>
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0.1 }}
                                            whileHover={{ scale: 1.1 }}
                                            whileInView={{
                                                opacity: 1,
                                                scale: 1.0,
                                                transition: {
                                                    duration: 1
                                                }
                                            }}
                                            whileTap={{ scale: 0.9 }}
                                            viewport={{ once: true }}
                                        >
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: 'auto 0' }}
                                                    variant="body2"
                                                    color={candidVideoLink === "" ? "grey" : "primary"}
                                                >
                                                    {candidVideoLink !== "" ? candidVideoLink.split("|")[0] : "Processing..."}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color={candidVideoLink === "" ? "grey" : "primary"}>
                                                        <VideoCameraFrontIcon />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ m: "auto 0" }}>
                                                    <Typography variant="h6" component="span">
                                                        Candid Video
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {candidVideoLink !== "" ?
                                                            <Link to={candidVideoLink.split("|")[1]} target="_blank" rel="noopener noreferrer">Link</Link>
                                                            : null}
                                                    </Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </motion.div>
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0.1 }}
                                            whileHover={{ scale: 1.1 }}
                                            whileInView={{
                                                opacity: 1,
                                                scale: 1.0,
                                                transition: {
                                                    duration: 1
                                                }
                                            }}
                                            viewport={{ once: true }}
                                        >
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: 'auto 0' }}
                                                    align="right"
                                                    variant="body2"
                                                    color={traditionalVideoLink === "" ? "grey" : "primary"}
                                                >
                                                    {traditionalVideoLink !== "" ? traditionalVideoLink.split("|")[0] : "Processing..."}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color={traditionalVideoLink === "" ? "grey" : "primary"}>
                                                        <MovieIcon />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ m: "auto 0" }}>
                                                    <Typography variant="h6" component="span">
                                                        Traditional Video
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        {traditionalVideoLink !== "" ?
                                                            <Link to={traditionalVideoLink.split("|")[1]} target="_blank" rel="noopener noreferrer">Link</Link>
                                                            : null}
                                                    </Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </motion.div>
                                        <motion.div
                                            initial={{ opacity: 0, scale: 0.1 }}
                                            whileHover={{ scale: 1.1 }}
                                            whileInView={{
                                                opacity: 1,
                                                scale: 1.0,
                                                transition: {
                                                    duration: 1
                                                }
                                            }}
                                            whileTap={{ scale: 0.9 }}
                                            viewport={{ once: true }}
                                        >
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: 'auto 0' }}
                                                    align="right"
                                                    variant="body2"
                                                    color={albumLink === "" ? "grey" : "primary"}
                                                >
                                                    {albumLink !== "" ? albumLink : "Processing..."}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color={albumLink === "" ? "grey" : "primary"}>
                                                        <MovieIcon />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ m: "auto 0" }}>
                                                    <Typography variant="h6" component="span">
                                                        Album
                                                    </Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        </motion.div>
                                    </Timeline>
                                </Grid>
                            </Grid>
                        </motion.div>
                    </Grid>
                }
            </Grid>
            {
                !loading ? <Footer /> : null
            }
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000}
                open={showAlert} onClose={() => setShowAlert(false)}>
                <SnackbarContent message={alertMessage} sx={{
                    borderRadius: 3,
                    color: 'primary.main',
                    justifyContent: 'center',
                    backgroundColor: 'primary.light',
                }} />
            </Snackbar>
        </ThemeProvider>
    )
}