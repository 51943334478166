import { createTheme } from "@mui/material";

export default createTheme({
    palette: {
        primary: {
            light: '#ffffff',
            main: '#020202',
            dark: '#020202',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#ffffff',
            main: '#ffffff',
            dark: '#ffffff',
            contrastText: '#ffffff',
        },
    },
    typography: {
        fontFamily: [
            'Google Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    }
});