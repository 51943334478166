import {
    Box, Button,
    Grid, Snackbar, SnackbarContent, Stack,
    TextField,
    ThemeProvider,
    Typography
} from "@mui/material";

import { BrowserView, MobileView } from 'react-device-detect';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import NavigationIcon from '@mui/icons-material/Navigation';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import "animate.css";
import Carousel from "react-material-ui-carousel/dist/components/Carousel";
import HomeCarousel from "../components/HomeCarousel";
import Footer from "../components/Footer";
import GlobalTheme from "../theme/GlobalTheme";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { motion } from "framer-motion";

export default function Home() {
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const handleSendMessage = () => {
        axios.post("/signature-moments-data-ingest", {
            action: 'CREATE_CONTACT_ENQUIRY',
            name: name,
            phone: phone,
            message: message
        }).then(response => {
            setLoading(false);
            if (response.status === 200) {
                setAlertMessage("Success!");
                setShowAlert(true);
            } else {
                setAlertMessage("Failed! Please try again.");
                setShowAlert(true);
                setSubmitDisabled(false);
            }
        }).catch(error => {
            setLoading(false);
            setAlertMessage("Failed! Please try again.");
            setShowAlert(true);
            setSubmitDisabled(false);
        })
    };

    return (
        <ThemeProvider theme={GlobalTheme}>
            <Grid container component="main" sx={{
                backgroundColor: 'primary.main'
            }}>
                <HomeCarousel />
                <Grid item xs={12} md={6} sx={{ backgroundColor: 'primary.main', alignSelf: 'center', px: 4 }}>
                    <Typography variant={'h4'} fontWeight={500} color={'primary.light'}>
                        Why Signature moments?
                    </Typography>
                    <Typography variant={'subtitle1'} fontWeight={500} color={'primary.light'} sx={{ pt: 3, pb: 3 }}>
                        We aren't your photographers alone, we are a part of your family.
                        <br></br>
                        <br></br>
                        We sign all your happy moments and stay with you in the form of memories during the sad ones.
                        <br></br>
                        <br></br>
                        SM photography is all about people being real and then letting us capture that moment to
                        remember it forever.
                        <br></br>
                        <br></br>
                        We are incredibly blessed to have a team of photographers who work with us on any given weekend
                        and with a smile on their faces throughout the event.
                        <br></br>
                        <br></br>
                        Real people, real stories, real moments.
                        <br></br>
                        <br></br>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ backgroundColor: 'primary.main', display: 'grid', alignSelf: 'center' }}>
                    <BrowserView>
                        <Carousel animation='fade' height='100vh' indicators={false}>
                            <img alt="image1" src='/carousel/carousel-2-1.jpeg'
                                style={{
                                    objectFit: 'cover', height: '100vh', width: '50vw', objectPosition: '50% 75%'
                                }} />
                            <img alt="image2" src='/carousel/carousel-2-2.jpeg'
                                style={{
                                    objectFit: 'cover', height: '100vh', width: '50vw', objectPosition: '50% 100%'
                                }} />
                            <img alt="image3" src='/carousel/carousel-2-4.jpeg'
                                style={{
                                    objectFit: 'cover', height: '100vh', width: '50vw', objectPosition: '54% 50%'
                                }} />
                            <img alt="image4" src='/carousel/carousel-2-5.jpeg'
                                style={{
                                    objectFit: 'cover', height: '100vh', width: '50vw', objectPosition: '50% 90%'
                                }} />
                            <img alt="image5" src='/carousel/carousel-2-6.jpeg'
                                style={{
                                    objectFit: 'cover', height: '100vh', width: '50vw', objectPosition: '50% 50%'
                                }} />
                            <img alt="image6" src='/carousel/carousel-2-7.jpeg'
                                style={{
                                    objectFit: 'cover', height: '100vh', width: '50vw', objectPosition: '50% 90%'
                                }} />
                            <img alt="image7" src='/carousel/carousel-2-8.jpeg'
                                style={{
                                    objectFit: 'cover', height: '100vh', width: '50vw', objectPosition: '50% 50%'
                                }} />
                        </Carousel>
                    </BrowserView>
                    <MobileView>
                        <Carousel animation='fade' height='100vh' indicators={false}>
                            <img alt="image1" src='/carousel/carousel-2-1.jpeg'
                                style={{ objectFit: 'cover', height: '100vh', width: '100vw', objectPosition: '45% 50%' }} />
                            <img alt="image2" src='/carousel/carousel-2-2.jpeg'
                                style={{ objectFit: 'cover', height: '100vh', width: '100vw', objectPosition: '100% 50%' }} />
                            <img alt="image3" src='/carousel/carousel-2-4.jpeg'
                                style={{ objectFit: 'cover', height: '100vh', width: '100vw', objectPosition: '52% 50%' }} />
                            <img alt="image4" src='/carousel/carousel-2-5.jpeg'
                                style={{ objectFit: 'cover', height: '100vh', width: '100vw', objectPosition: '40% 50%' }} />
                            <img alt="image5" src='/carousel/carousel-2-6.jpeg'
                                style={{ objectFit: 'cover', height: '100vh', width: '100vw', objectPosition: '60% 50%' }} />
                            <img alt="image6" src='/carousel/carousel-2-7.jpeg'
                                style={{ objectFit: 'cover', height: '100vh', width: '100vw', objectPosition: '70% 50%' }} />
                            <img alt="image7" src='/carousel/carousel-2-8.jpeg'
                                style={{ objectFit: 'cover', height: '100vh', width: '100vw', objectPosition: '46% 50%' }} />
                        </Carousel>
                    </MobileView>
                </Grid>
                <Grid item xs={0} md={6} sx={{
                    height: '100vh',
                    alignItems: 'center',
                    display: { xs: 'none', md: 'grid' }
                }}>
                    <Grid>
                        <Grid item xs={9} sx={{ p: 3, pl: 6 }}>
                            <Typography variant={'h4'} fontWeight={500} color={'primary.light'}>
                                Wedding
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ p: 3, pl: 6 }}>
                            <Typography variant={'h4'} fontWeight={500} color={'primary.light'}>
                                Maternity
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ p: 3, pl: 6 }}>
                            <Typography variant={'h4'} fontWeight={500} color={'primary.light'}>
                                Kids
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ p: 3, pl: 6 }}>
                            <Typography variant={'h4'} fontWeight={500} color={'primary.light'}>
                                Studio Portraits
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ p: 3, pl: 6 }}>
                            <Typography variant={'h4'} fontWeight={500} color={'primary.light'}>
                                Commercial
                            </Typography>
                        </Grid>
                        <Grid item xs={9} sx={{ p: 3, pl: 6 }}>
                            <Typography variant={'h4'} fontWeight={500} color={'primary.light'}>
                                Workshops
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}
                    sx={{ backgroundColor: 'primary.light', alignSelf: 'center', display: 'block' }}>
                    <Box>
                        <Grid container rowSpacing={{ xs: 2, md: 3 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ p: { xs: 2, md: 4 } }}>
                            <Grid item>
                                <Typography variant={'h4'} fontWeight={500} sx={{ pt: 2, pb: 2, px: 2 }}>
                                    Looking to capture a memory with us?
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={'subtitle1'} fontWeight={500} sx={{ px: 2 }}>
                                    Embark on a captivating visual voyage with our photography and videography services,
                                    where we weave your special moments into an enchanting narrative.
                                    <br></br>
                                    <br></br>
                                    Our diverse package options ensure that every detail, every emotion, and every cherished instant is beautifully
                                    preserved for a lifetime. Whether you seek the elegance of photography, the motion of videography,
                                    or the perfect blend of both, our packages cater to your desires.
                                    <br></br>
                                    <br></br>
                                    Choose not just a service, but an experience that mirrors your unique story. Explore our packages
                                    and let us transform your
                                    moments into a masterpiece.
                                </Typography>
                            </Grid>
                        </Grid>
                        <div style={{ textAlign: 'right', paddingBottom: '2rem', paddingTop: '1rem' }}>
                            <Button variant="contained"
                                sx={{ width: '45%', height: '4rem', borderRadius: 0 }}
                                onClick={() => navigate("/weddingenquiry")}>Book Now</Button>
                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Typography variant={'h3'} fontWeight={500} color={'primary.light'} sx={{ p: 4, pt: 8 }}>
                        Contact
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ alignSelf: 'center' }}>
                    <motion.div
                        initial={{
                            x: -300,
                        }}
                        whileInView={{
                            x: 0,
                            transition: {
                                duration: 1.0
                            }
                        }}
                    >
                        <Grid container>
                            <Grid item xs={2} sx={{ p: 2, textAlign: 'right', alignSelf: 'center' }}>
                                <CallIcon sx={{ color: 'white' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ p: 2 }}>
                                <Typography variant={'subtitle1'} fontWeight={500} color={'primary.light'}>
                                    <a href="tel:+919361271285" style={{ color: 'white', textDecoration: 'none' }}>+91 93612 71285</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ p: 2, textAlign: 'right', alignSelf: 'center' }}>
                                <WhatsAppIcon sx={{ color: 'white' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ p: 2 }}>
                                <Typography variant={'subtitle1'} fontWeight={500} color={'primary.light'}>
                                    <a href="https://wa.me/919361271285" target="_blank"
                                        style={{ color: 'white', textDecoration: 'none' }}>+91 93612 71285</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ p: 2, textAlign: 'right', alignSelf: 'center' }}>
                                <InstagramIcon sx={{ color: 'white' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ p: 2 }}>
                                <Typography variant={'subtitle1'} fontWeight={500} color={'primary.light'}>
                                    <a href="https://www.instagram.com/signature_moments" target="_blank"
                                        style={{ color: 'white', textDecoration: 'none' }}>signature_moments</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ p: 2, textAlign: 'right', alignSelf: 'center' }}>
                                <YouTubeIcon sx={{ color: 'white' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ p: 2 }}>
                                <Typography variant={'subtitle1'} fontWeight={500} color={'primary.light'}>
                                    <a href="https://youtube.com/c/signaturemomentsoff" target="_blank"
                                        style={{ color: 'white', textDecoration: 'none' }}>Signature Moments</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ p: 2, textAlign: 'right', alignSelf: 'center' }}>
                                <EmailIcon sx={{ color: 'white' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ p: 2 }}>
                                <Typography variant={'subtitle1'} fontWeight={500} color={'primary.light'}>
                                    <a href="mailto:info@singaturemoments.co" target="_blank"
                                        style={{ color: 'white', textDecoration: 'none' }}>info@singaturemoments.co</a>
                                </Typography>
                            </Grid>
                            <Grid item xs={2} sx={{ p: 2, textAlign: 'right', alignSelf: 'center' }}>
                                <NavigationIcon sx={{ color: 'white' }} />
                            </Grid>
                            <Grid item xs={9} sx={{ p: 2 }}>
                                <Typography variant={'subtitle1'} fontWeight={500} color={'primary.light'}>
                                    <a href="https://maps.google.com?q=Signature+Moments" target="_blank"
                                        style={{ color: 'white', textDecoration: 'none' }}>
                                        16A New No: 26B Kongu nagar,
                                        <br></br>
                                        Ramanathapuram,
                                        <br></br>
                                        Coimbatore - 641 005.
                                    </a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </motion.div>

                </Grid>
                <Grid item xs={12} md={6} sx={{ alignSelf: 'center' }}>
                    <motion.div
                        initial={{
                            x: 300,
                        }}
                        whileInView={{
                            x: 0,
                            transition: {
                                duration: 1.0
                            }
                        }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <Box sx={{ mt: 4, pb: 4, backgroundColor: 'primary.light' }}>
                            <Stack spacing={4} sx={{ p: { xs: 2, md: 4 }, pb: 4 }}>
                                <Typography variant={'h5'} fontWeight={500} sx={{ pt: { xs: 2, md: 0 } }}>
                                    We would love to hear from you
                                </Typography>
                                <TextField label="Name" type='text' variant="outlined" fullWidth sx={{ mt: 3 }} value={name} onChange={(event) => {
                                    setName(event.target.value as string);
                                }} />
                                <TextField label="Phone" type='text' variant="outlined" fullWidth sx={{ mt: 3 }} value={phone} onChange={(event) => {
                                    setPhone(event.target.value as string);
                                }} />
                                <TextField label="Message" type='text' variant="outlined" fullWidth multiline rows={5}
                                    sx={{ mt: 3 }} value={message} onChange={(event) => {
                                        setMessage(event.target.value as string);
                                    }} />
                            </Stack>
                            <div style={{ textAlign: 'right', paddingTop: '1rem' }}>
                                <LoadingButton variant="contained" loading={loading} disabled={submitDisabled}
                                    sx={{ width: '45%', height: '4rem', borderRadius: 0 }} onClick={() => {
                                        if (name === null || name === "") {
                                            setAlertMessage("Name is required!");
                                            setShowAlert(true);
                                        } else if (phone === null || phone === "") {
                                            setAlertMessage("Phone is required!");
                                            setShowAlert(true);
                                        } else if (message === null || message === "") {
                                            setAlertMessage("Message is required!");
                                            setShowAlert(true);
                                        } else {
                                            setLoading(true);
                                            setSubmitDisabled(true);
                                            setAlertMessage("Sending message...");
                                            setShowAlert(true);
                                            handleSendMessage();
                                        }
                                    }}>Submit</LoadingButton>
                            </div>
                        </Box>
                    </motion.div>
                </Grid>
            </Grid>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000}
                open={showAlert} onClose={() => setShowAlert(false)}>
                <SnackbarContent message={alertMessage} sx={{
                    borderRadius: 3,
                    color: 'primary.main',
                    justifyContent: 'center',
                    backgroundColor: 'primary.light',
                }} />
            </Snackbar>
            <Footer />
        </ThemeProvider>
    )
}